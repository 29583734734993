import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const water =
  process.env.PUBLIC_URL + "/assets/images/screen1/options/water.svg";

const electricity =
  process.env.PUBLIC_URL + "/assets/images/screen1/options/electricity.svg";

const sewage =
  process.env.PUBLIC_URL + "/assets/images/screen1/options/sewage.svg";

const safetyHeroOptions = [
  {
    id: 0,
    title: "Electricity",
    thumbnail: electricity,
    value: "electricity" as ISelectedQuizTyp,
  },
  {
    id: 1,
    title: "Water",
    thumbnail: water,
    value: "water" as ISelectedQuizTyp,
  },
  {
    id: 2,
    title: "Sewage",
    thumbnail: sewage,
    value: "sewage" as ISelectedQuizTyp,
  },
];

const waterQuiz = [
  {
    id: 0,
    question:
      "تنظيف الأسنان بالفرشاة مع ترك الصنبور مفتوحًا. هل يجب إغلاق الصنبور أثناء تنظيف الأسنان بالفرشاة؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
  },
  {
    id: 1,
    question:
      "تسرب المياه من أحد الأنابيب أسفل الحوض، هل يجوز تجاهل التسربات البسيطة في الأنابيب؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
  },
  {
    id: 2,
    question:
      "غسل السيارة بخرطوم ماء مفتوح طوال الوقت هل من الصحيح استخدام خرطوم ماء مفتوح عند غسل السيارة؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
  },
];

const electricityQuiz = [
  {
    id: 0,
    question:
      "يوجد جهاز كمبيوتر قيد التشغيل ولا يستخدمه أحد. هل يجب إيقاف تشغيل الأجهزة الإلكترونية عند عدم استخدامها؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
  },
  {
    id: 1,
    question:
      "يوجد سلك كهربائي مكشوف في الغرفة. هل من الآمن لمس الأسلاك الكهربائية المكشوفة؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
  },
  {
    id: 2,
    question:
      "تشغيل عدة أجهزة كهربائية في نفس المقبس الكهربائي. هل يصح استخدام مقبس واحد لتشغيل عدة أجهزة؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
  },
];

const sewageQuiz = [
  {
    id: 0,
    question:
      "تنظيف المطبخ ورمي الزيوت في الحوض هل يجوز رمي الزيوت المستعملة في الحوض؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
  },
  {
    id: 1,
    question:
      "هل القيام بفتح فتحات الصرف الصحي يعد خطر على سلامة الجميع؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
  },
  {
    id: 2,
    question:
      "هل نقترب من البرك المائية المتجمعة حول أعمدة الكهرباء أو أعمدة الإنارة أو فتحات الصرف الصرف الصحي ؟",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
  },
];

export type ISelectedQuizTyp = "water" | "sewage" | "electricity";

export interface ISafetyHeroOptions {
  id: number;
  title: string;
  thumbnail: string;
  value: ISelectedQuizTyp;
}

export interface ISafetyQuiz {
  id: number;
  question: string;
  optionA: string;
  optionB: string;
  correctAnswer: string;
}

interface IRoot {
  safetyHeroOptions: ISafetyHeroOptions[];
  quizData: ISafetyQuiz[];
  selectedQuizType: ISelectedQuizTyp | null;
  currentQuestion: ISafetyQuiz | null;
  remainingQuestions: ISafetyQuiz[];
  correctAnswers: number;
  selectedQuizLength: number;
  currentIndex: number;
}

const initialState: IRoot = {
  safetyHeroOptions: safetyHeroOptions,
  quizData: [],
  selectedQuizType: null,
  currentQuestion: null,
  remainingQuestions: [],
  correctAnswers: 0,
  selectedQuizLength: 0,
  currentIndex: 0,
};

const slice = createSlice({
  name: "safetyHero",
  initialState,
  reducers: {
    setQuizData(state, action: PayloadAction<ISelectedQuizTyp>) {
      const type = action.payload;
      if (type === "water") {
        state.selectedQuizLength = waterQuiz.length;
        state.quizData = waterQuiz;
        state.currentQuestion = waterQuiz[0];
        state.remainingQuestions = waterQuiz.slice(1);
        state.currentIndex = 0;
      } else if (type === "sewage") {
        state.selectedQuizLength = sewageQuiz.length;
        state.quizData = sewageQuiz;
        state.currentQuestion = sewageQuiz[0];
        state.remainingQuestions = sewageQuiz.slice(1);
        state.currentIndex = 0;
      } else if (type === "electricity") {
        state.selectedQuizLength = electricityQuiz.length;
        state.quizData = electricityQuiz;
        state.currentQuestion = electricityQuiz[0];
        state.remainingQuestions = electricityQuiz.slice(1);
        state.currentIndex = 0;
      }
    },
    selectedQuizType(state, action: PayloadAction<ISelectedQuizTyp>) {
      state.selectedQuizType = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    currentQuestion(state, action: PayloadAction<ISafetyQuiz | null>) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(state, action: PayloadAction<ISafetyQuiz[]>) {
      state.remainingQuestions = action.payload;
    },
    currentIndex(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload;
    },
    resetAll(state) {
      state.selectedQuizType = null;
      state.currentQuestion = null;
      state.remainingQuestions = [];
      state.correctAnswers = 0;
      state.quizData = [];
      state.currentIndex = 0;
    },
  },
});

export const SafetyHeroActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
