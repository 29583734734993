import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const processThumbnail =
  process.env.PUBLIC_URL + "/assets/images/sewageTreatment/options/process.png";
const usageThumbnail =
  process.env.PUBLIC_URL + "/assets/images/sewageTreatment/options/usage.png";
const quizGameThumbnail =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/options/quizGame.png";

const step1 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/process/processStep1.jpg";

const step2 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/process/processStep2.jpg";

const step3 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/process/processSTep3.jpg";

const step4 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/process/processStep4.jpg";

const usageThumbnail1 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/usage/usageStep1.jpg";
const usageThumbnail2 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/usage/usageStep2new.jpg";

const usageIcon1 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/usage/usageIcon1.svg";
const usageIcon2 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/usage/usageIcon2.svg";
const usageIcon3 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/usage/usageIcon3.svg";
const usageIcon4 =
  process.env.PUBLIC_URL +
  "/assets/images/sewageTreatment/usage/usageIcon4.svg";

const safetyHeroOptions = [
  {
    id: 0,
    title: "عملية معالجة مياه الصرف الصحي",
    thumbnail: processThumbnail,
    value: "process" as ISelectedQuizTyp,
  },
  {
    id: 1,
    title: "استخدام المياه المعالجة",
    thumbnail: usageThumbnail,
    value: "usage" as ISelectedQuizTyp,
  },
  {
    id: 2,
    title: "لعبة اختبار",
    thumbnail: quizGameThumbnail,
    value: "quizGame" as ISelectedQuizTyp,
  },
];

export const screen2Questions = [
  {
    id: 1,
    question: "هل يجوز تشغيل التلفاز طوال الليل؟",
    options: {
      optionA: "نعم، لا يؤلم.",
      optionB: "لا، يجب إيقاف تشغيله عند عدم استخدامه.",
      optionC: "يمكن تركه حتى أستيقظ.",
    },
    correctOptionValue: "نعم، لا يؤلم.",
  },
  {
    id: 2,
    question:
      "استخدام شاحن هاتف غير أصلي هل من الممكن استخدام شاحن هاتف غير أصلي؟",
    options: {
      optionA: "نعم لا يوجد مشكلة في ذلك.",
      optionB: "لا، قد يسبب حريقًا.",
      optionC: "يمكن استخدامه في بعض الأحيان.",
    },
    correctOptionValue: "نعم لا يوجد مشكلة في ذلك.",
  },
  {
    id: 3,
    question:
      "استخدام المصابيح العادية في المنزل هل يجب أن أستخدم المصابيح العادية بدلاً من المصابيح الموفرة للطاقة؟",
    options: {
      optionA: "نعم، إنهم أكثر إشراقا.",
      optionB: "لا، المصابيح الموفرة للطاقة أفضل.",
      optionC: "يعتمد على الحاجة.",
    },
    correctOptionValue: "نعم، إنهم أكثر إشراقا.",
  },
  {
    id: 4,
    question:
      "إصلاح الأسلاك الكهربائية المكشوفة. ماذا يجب أن تفعل إذا رأيت سلكًا كهربائيًا مكشوفًا؟",
    options: {
      optionA: "لمسها لمعرفة ما إذا كانت تعمل.",
      optionB: "أخبر شخصًا بالغًا أو متخصصًا.",
      optionC: "تجاهله لأنه ليس خطيرًا.",
    },
    correctOptionValue: "أخبر شخصًا بالغًا أو متخصصًا.",
  },
];

export const waterQuizQuestions = [
  {
    id: 1,
    question:
      "ترك الصنبور مفتوحا أثناء غسل اليدين هل يجوز ترك الصنبور مفتوحا أثناء غسل اليدين؟",
    options: {
      optionA: "نعم، إنه أسرع.",
      optionB: "لا، يجب إغلاقه أثناء الغسيل.",
      optionC: "يمكن تركه مفتوحا قليلا.",
    },
    correctOptionValue: "نعم، إنه أسرع.",
  },
  {
    id: 2,
    question:
      "استخدام دلو لغسل الماء بدلا من الخرطوم ما هو الخيار الأفضل لغسل السيارة؟",
    options: {
      optionA: "استخدام خرطوم مياه مفتوح.",
      optionB: "استخدام دلو لتوفير المياه.",
      optionC: "غسله بدون ماء.",
    },
    correctOptionValue: "غسله بدون ماء.",
  },
  {
    id: 3,
    question:
      "يوجد تسرب في صنبور المياه ماذا يجب أن تفعل إذا رأيت تسرب في صنبور المياه؟",
    options: {
      optionA: "تجاهله، لا يهم.",
      optionB: "أغلق الصنبور وأخبر شخصًا بالغًا.",
      optionC: "حاول إصلاحه بنفسك دون أن تعرف.",
    },
    correctOptionValue: "تجاهله، لا يهم.",
  },
  {
    id: 4,
    question:
      "شرب الماء من زجاجة مستعملة هل من الممكن شرب الماء من زجاجة قديمة دون تنظيفها؟",
    options: {
      optionA: "نعم لا بأس.",
      optionB: "لا، يمكن أن يكون غير صحي.",
      optionC: "يعتمد على مدة الاستخدام.",
    },
    correctOptionValue: "نعم لا بأس.",
  },
];

export const QuizDataScreen6 = [
  {
    id: 1,
    question: "ما هو الهدف الأساسي من معالجة مياه الصرف الصحي؟",
    options: {
      optionA: "تحسين طعم المياه",
      optionB: "الحفاظ على البيئة وصحة الإنسان",
      optionC: "زيادة كمية المياه في الأنهار",
    },
    correctOptionValue: "الحفاظ على البيئة وصحة الإنسان",
  },
  {
    id: 2,
    question: "في أي مرحلة تُزال الأجسام الكبيرة مثل البلاستيك والأوراق؟",
    options: {
      optionA: "المرحلة الثانوية",
      optionB: "المرحلة الأولية",
      optionC: "المعالجة المتقدمة",
    },
    correctOptionValue: "المرحلة الأولية",
  },
  {
    id: 3,
    question: "ما الدور الذي تقوم به البكتيريا في المعالجة البيولوجية؟",
    options: {
      optionA: "تقتل الجراثيم",
      optionB: "تُنظف الأوساخ الكبيرة",
      optionC: "تحلل المواد العضوية",
    },
    correctOptionValue: "تحلل المواد العضوية",
  },
  {
    id: 4,
    question: "ما الذي يمكن فعله بالمياه المعالجة بعد اكتمال عملية المعالجة؟",
    options: {
      optionA: "التخلص منها في مكبات النفايات",
      optionB: "إطلاقها في الأنهار أو إعادة استخدامها في الري والصناعة",
      optionC: "تخزينها في خزانات تحت الأرض",
    },
    correctOptionValue:
      "إطلاقها في الأنهار أو إعادة استخدامها في الري والصناعة",
  },
  {
    id: 5,
    question: "لماذا يُعتبر إعادة استخدام المياه المعالجة مهماً؟",
    options: {
      optionA: "لتقليل الضغط على الموارد المائية",
      optionB: "لتحسين طعم المياه",
      optionC: "لزيادة كمية المياه المالحة",
    },
    correctOptionValue: "لتقليل الضغط على الموارد المائية",
  },
  {
    id: 6,
    question:
      "ما الذي يحدث للمواد الثقيلة مثل الرمل والطين في المعالجة الأولية؟",
    options: {
      optionA: "تُزال باستخدام مرشحات",
      optionB: "تترسب في قاع الأحواض",
      optionC: "تُرفع إلى السطح",
    },
    correctOptionValue: "تترسب في قاع الأحواض",
  },
  {
    id: 7,
    question: "كيف يمكن لطلاب المدارس المساعدة في الحفاظ على المياه؟",
    options: {
      optionA: "رمي القمامة في الأنهار",
      optionB: "المشاركة في حملات تنظيف المياه",
      optionC: "زيادة استخدام المياه",
    },
    correctOptionValue: "المشاركة في حملات تنظيف المياه",
  },
  {
    id: 8,
    question: "ما هي المرحلة الأولى في معالجة مياه الصرف الصحي؟",
    options: {
      optionA: "الترشيح بالأشعة فوق البنفسجية",
      optionB: "المعالجة الأولية",
      optionC: "المعالجة البيولوجية",
    },
    correctOptionValue: "المعالجة الأولية",
  },
  {
    id: 9,
    question: `ما دور البكتيريا "الجيدة" في مرحلة المعالجة البيولوجية؟`,
    options: {
      optionA: "تساعد في إزالة الأجسام الكبيرة",
      optionB: "تحلل المواد العضوية",
      optionC: "تقتل الجراثيم بالأشعة",
    },
    correctOptionValue: "تحلل المواد العضوية",
  },
  // {
  //   id: 10,
  //   question: "ما هي التقنية المستخدمة لتعقيم المياه في المرحلة المتقدمة؟",
  //   options: {
  //     optionA: "الأحواض الهوائية",
  //     optionB: "الأشعة فوق البنفسجية",
  //     optionC: "الترشيح الكيميائي",
  //   },
  //   correctOptionValue: "الأشعة فوق البنفسجية",
  // },
  {
    id: 10,
    question: "ما هو أحد استخدامات المياه المعالجة بعد انتهاء جميع المراحل؟",
    options: {
      optionA: "تزويد المنازل بالمياه الصالحة للشرب مباشرة",
      optionB: "استخدامها في الري والصناعات",
      optionC: "التخلص منها في الصحاري",
    },
    correctOptionValue: "استخدامها في الري والصناعات",
  },
  {
    id: 11,
    question:
      "كيف تساعد معالجة مياه الصرف الصحي في الحفاظ على التنوع البيولوجي؟",
    options: {
      optionA: "بمنع دخول النفايات الكبيرة إلى المحيط",
      optionB: "بإطلاق المياه المعالجة النظيفة إلى الأنهار والبحار",
      optionC: "بإضافة المواد الكيميائية إلى المياه",
    },
    correctOptionValue: "بإطلاق المياه المعالجة النظيفة إلى الأنهار والبحار",
  },
  {
    id: 12,
    question: "كيف تساهم معالجة مياه الصرف الصحي في حماية الصحة العامة؟",
    options: {
      optionA: "عن طريق إزالة الفيروسات والبكتيريا المسببة للأمراض",
      optionB: "عن طريق زيادة الأملاح في المياه",
      optionC: "عن طريق تبريد المياه",
    },
    correctOptionValue: "عن طريق إزالة الفيروسات والبكتيريا المسببة للأمراض",
  },
];

export const processScreens = [
  {
    id: 0,
    title: " المعالجة الفيزيائية",
    subTitle: "عرض خطوات معالجة مياه الصرف الصحي",
    options: [
      {
        id: 0,
        title: "إزالة الأجسام الكبيرة",
        subTitle:
          " تستخدم شباك ومصافي كبيرة لإزالة الأشياء الكبيرة مثل الأوراق، والأغصان، والبلاستيك، والمواد الصلبة الأخرى التي قد تكون موجودة في المياه.",
      },
    ],
    thumbnail: step1,
  },
  {
    id: 1,
    title: " المعالجة الأولية",
    subTitle: "عرض خطوات معالجة مياه الصرف الصحي",
    options: [
      {
        id: 0,
        title: "الفصل بالترسيب",
        subTitle:
          " بعد إزالة الأشياء الكبيرة، تُترك المياه في أحواض ضخمة حيث تسمح للمواد الثقيلة مثل الرمل والطين بالترسب في قاع الحوض، بينما ترتفع المواد الأخف مثل الزيوت إلى السطح وتُزال.",
      },
    ],
    thumbnail: step2,
  },
  {
    id: 2,
    title: "  المعالجة الثانوية",
    subTitle: "عرض خطوات معالجة مياه الصرف الصحي",
    options: [
      {
        id: 0,
        title: `الأحواض الهوائية`,
        subTitle: ` في هذه المرحلة، تُضاف الأحواض الهوائية حيث تتواجد البكتيريا "الجيدة" التي تتغذى على المواد العضوية الموجودة في المياه. هذه البكتيريا تحول الفضلات إلى مواد أقل ضررًا مثل ثاني أكسيد الكربون والماء.`,
      },
      {
        id: 1,
        title: "أنظمة التفاعل البيولوجي",
        subTitle:
          " هناك أنظمة مختلفة مثل نظام التدفئة الهوائية (Activated Sludge System) ونظام الأكسجين المتحلل (Trickling Filter) التي تساعد في تعزيز نمو البكتيريا المفيدة وتحسين عملية التنقية.",
      },
    ],
    thumbnail: step3,
  },
  {
    id: 3,
    title: "  المعالجة النهائية وإعادة الاستخدام",
    subTitle: "عرض خطوات معالجة مياه الصرف الصحي",
    options: [
      {
        id: 0,
        title: "إضافة مادة الكلورين",
        subTitle:
          " يتم نقل المياه إلى الاحواض النهائية وإضافة مادة الكلورين لتنقية المياه من جميع المخلفات الدقيقة والبكتيريا لإعادة استخدامها.",
      },
      {
        id: 1,
        title: "إعادة المياه إلى الطبيعة",
        subTitle:
          "بعد معالجة المياه بشكل كامل، يمكن إطلاقها في الأنهار أو البحار دون أن تضر البيئة.",
      },
      {
        id: 2,
        title: "إعادة الاستخدام",
        subTitle:
          " يمكن أيضاً إعادة استخدام المياه المعالجة في أغراض مثل الري في الزراعة، أو في الصناعات التي تحتاج إلى مياه نظيفة، أو حتى في بعض الأحيان في الاستخدامات المنزلية مثل التنظيف.",
      },
    ],
    thumbnail: step4,
  },
];

export const usageStepsList = [
  {
    id: 0,
    title: "حماية البيئة",
    options: [
      {
        id: 0,
        title: "منع التلوث",
        subTitle:
          " معالجة مياه الصرف الصحي تمنع تلوث الأنهار والبحار، مما يحافظ على الحياة البحرية والنباتات.",
        icon: usageIcon1,
      },
      {
        id: 1,
        title: "الحفاظ على التنوع البيولوجي",
        subTitle:
          " المياه النظيفة تساعد في الحفاظ على التنوع البيولوجي للكائنات الحية في البيئة.",
        icon: usageIcon2,
      },
    ],
    thumbnail: usageThumbnail1,
  },
  {
    id: 1,
    title: "الحفاظ على الموارد المائية",
    options: [
      {
        id: 0,
        title: "إعادة الاستخدام",
        subTitle:
          "إعادة استخدام المياه المعالجة يقلل من الضغط على الموارد المائية الطبيعية، مما يضمن توفر المياه للأجيال القادمة.",
        icon: usageIcon3,
      },
      {
        id: 1,
        title: "الاستدامة",
        subTitle:
          " استخدام المياه المعالجة في الصناعات والزراعة يساعد في تحقيق الاستدامة البيئية والاقتصادية.",
        icon: usageIcon4,
      },
    ],
    thumbnail: usageThumbnail2,
  },
];

export interface IProcessSteps {
  id: number;
  title: string;
  subTitle: string;
  options: {
    id: number;
    title: string;
    subTitle: string;
  }[];
  thumbnail: string;
}
export interface IUsageSlides {
  id: number;
  title: string;
  options: {
    id: number;
    title: string;
    subTitle: string;
    icon: string;
  }[];
  thumbnail: string;
}

export interface IScreen2Options {
  optionA: string | null;
  optionB: string | null;
  optionC: string | null;
  // optionD: string | null;
}

export interface IScreen2Questions {
  id: number;
  question: string;
  options: IScreen2Options;
  correctOptionValue: string;
}

export type ISelectedQuizTyp = "process" | "usage" | "quizGame";

export interface ISafetyHeroOptions {
  id: number;
  title: string;
  thumbnail: string;
  value: ISelectedQuizTyp;
}

interface IRoot {
  currentQuestion: IScreen2Questions | null;
  remainingQuestions: IScreen2Questions[];
  correctAnswers: number;
  safetyHeroOptions: ISafetyHeroOptions[];
  selectedQuizType: ISelectedQuizTyp | null;
  selectedQuizLength: number;
  currentIndex: number;
}

const initialState: IRoot = {
  currentQuestion: QuizDataScreen6[0],
  remainingQuestions: QuizDataScreen6.slice(1),
  correctAnswers: 0,
  safetyHeroOptions: safetyHeroOptions,
  selectedQuizType: null,
  selectedQuizLength: 0,
  currentIndex: 0,
};

const slice = createSlice({
  name: "sewageTreatment",
  initialState,
  reducers: {
    setQuizData(state) {
      state.selectedQuizLength = QuizDataScreen6.length;
      state.currentQuestion = QuizDataScreen6[0];
      state.remainingQuestions = QuizDataScreen6.slice(1);
      state.currentIndex = 0;
    },
    initialQuizDat(state) {
      state.selectedQuizLength = QuizDataScreen6.length;
      state.currentQuestion = QuizDataScreen6[0];
      state.remainingQuestions = QuizDataScreen6.splice(1);
      state.currentIndex = 0;
    },
    currentQuestion(state, action: PayloadAction<IScreen2Questions | null>) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(state, action: PayloadAction<IScreen2Questions[]>) {
      state.remainingQuestions = action.payload;
    },
    currentIndex(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    selectedQuizType(state, action: PayloadAction<ISelectedQuizTyp>) {
      state.selectedQuizType = action.payload;
    },
    resetAll(state) {
      state.currentQuestion = initialState.currentQuestion;
      state.remainingQuestions = initialState.remainingQuestions;
      state.correctAnswers = 0;
      state.selectedQuizType = null;
      state.selectedQuizLength = 0;
      state.currentIndex = 0;
    },
  },
});

export const sewageTreatmentActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
