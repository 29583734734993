import { createBrowserRouter } from "react-router-dom";
import BaseLayout from "./components/base-layout";
import SuspenseLoader from "./components/loader/suspense-loader";
import { lazy, Suspense } from "react";
import CarbonFootprintDetails from "./contents/screen9/detail1";
import CarbonFootprintDetails2 from "./contents/screen9/detail2";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const PageNotFound = Loader(lazy(() => import("./components/page-not-found")));

const HomePage = Loader(lazy(() => import("./contents/homepage")));

const Screen4 = Loader(lazy(() => import("./contents/screen4")));
const SelectOptions = Loader(
  lazy(() => import("./contents/screen4/select-options"))
);
const RadiationOptions = Loader(
  lazy(() => import("./contents/screen4/options"))
);
const TimeRadiation = Loader(lazy(() => import("./contents/screen4/time")));
const DistanceRadiation = Loader(
  lazy(() => import("./contents/screen4/distance"))
);
const ShieldingRadiation = Loader(
  lazy(() => import("./contents/screen4/shielding"))
);
const SelectedCategory = Loader(
  lazy(() => import("./contents/screen4/selected-category"))
);
const RadiationResults = Loader(
  lazy(() => import("./contents/screen4/results"))
);
const Screen4ThankYou = Loader(
  lazy(() => import("./contents/screen4/thankYou"))
);

const Screen5 = Loader(lazy(() => import("./contents/screen5")));
const Screen5WelcomePage = Loader(
  lazy(() => import("./contents/screen5/welcome"))
);
const Screen5Instructions = Loader(
  lazy(() => import("./contents/screen5/instructions"))
);
const Screen5Quiz = Loader(lazy(() => import("./contents/screen5/quiz")));
const Section5ThankYouPage = Loader(
  lazy(() => import("./contents/screen5/thankYou"))
);

const Screen6 = Loader(lazy(() => import("./contents/screen6")));
const Screen6Options = Loader(lazy(() => import("./contents/screen6/options")));
const Screen6Instructions = Loader(
  lazy(() => import("./contents/screen6/instructions"))
);
const Screen6Process = Loader(lazy(() => import("./contents/screen6/process")));
const Screen6ProcessSteps = Loader(
  lazy(() => import("./contents/screen6/process/steps"))
);
const Screen6Usage = Loader(lazy(() => import("./contents/screen6/usage")));
const Screen6UsageSteps = Loader(
  lazy(() => import("./contents/screen6/usage/steps"))
);
const Screen6Quiz = Loader(lazy(() => import("./contents/screen6/quiz")));
const Screen6Score = Loader(lazy(() => import("./contents/screen6/score")));

const Screen6ThankYou = Loader(
  lazy(() => import("./contents/screen6/thankyou"))
);

const Screen7 = Loader(lazy(() => import("./contents/screen7")));
const Screen7Instructions = Loader(
  lazy(() => import("./contents/screen7/instructions"))
);
const Screen7Quiz = Loader(lazy(() => import("./contents/screen7/quiz")));
const Screen7Video = Loader(lazy(() => import("./contents/screen7/video")));
const Screen7Thankyou = Loader(
  lazy(() => import("./contents/screen7/thankyou"))
);
const Screen7Score = Loader(lazy(() => import("./contents/screen7/score")));
const Screen8 = Loader(lazy(() => import("./contents/screen8")));
const Screen8Options = Loader(lazy(() => import("./contents/screen8/options")));
const Screen8Viewer = Loader(lazy(() => import("./contents/screen8/viewer")));
const Screen8ThankYou = Loader(
  lazy(() => import("./contents/screen8/thankyou"))
);

const Screen9 = Loader(lazy(() => import("./contents/screen9")));
const Screen9Instructions = Loader(
  lazy(() => import("./contents/screen9/instructions"))
);
const Screen9Quiz = Loader(lazy(() => import("./contents/screen9/quiz")));
const Screen9Age = Loader(lazy(() => import("./contents/screen9/age")));
const Screen9Thankyou = Loader(
  lazy(() => import("./contents/screen9/thankyou"))
);
const Screen10 = Loader(lazy(() => import("./contents/screen10")));
const Screen10Instructions = Loader(
  lazy(() => import("./contents/screen10/instructions"))
);
const Screen10Age = Loader(lazy(() => import("./contents/screen10/age")));
const Screen10Quiz = Loader(lazy(() => import("./contents/screen10/quiz")));
const Screen10Thankyou = Loader(
  lazy(() => import("./contents/screen10/thankyou"))
);
const EcpDetails2 = Loader(lazy(() => import("./contents/screen10/detail2")));
const EcpDetails = Loader(lazy(() => import("./contents/screen10/detail1")));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [{ index: true, element: <HomePage /> }],
  },
  {
    path: "/screen4",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Screen4 /> },
      { path: "/screen4/options", element: <RadiationOptions /> },
      { path: "/screen4/select-options", element: <SelectOptions /> },
      { path: "/screen4/time", element: <TimeRadiation /> },
      { path: "/screen4/distance", element: <DistanceRadiation /> },
      { path: "/screen4/shielding", element: <ShieldingRadiation /> },
      { path: "/screen4/results", element: <RadiationResults /> },
      { path: "/screen4/options-selected", element: <SelectedCategory /> },
      { path: "/screen4/thankyou", element: <Screen4ThankYou /> },
    ],
  },
  {
    path: "/screen5",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Screen5 /> },
      { path: "/screen5/welcome", element: <Screen5WelcomePage /> },
      { path: "/screen5/instructions", element: <Screen5Instructions /> },
      { path: "/screen5/quiz", element: <Screen5Quiz /> },
      { path: "/screen5/thankyou", element: <Section5ThankYouPage /> },
    ],
  },
  {
    path: "/screen6",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Screen6 /> },
      { path: "/screen6/options", element: <Screen6Options /> },
      { path: "/screen6/instructions", element: <Screen6Instructions /> },
      { path: "/screen6/process", element: <Screen6Process /> },
      { path: "/screen6/process/steps", element: <Screen6ProcessSteps /> },
      { path: "/screen6/usage", element: <Screen6Usage /> },
      { path: "/screen6/usage/steps", element: <Screen6UsageSteps /> },
      { path: "/screen6/quiz", element: <Screen6Quiz /> },
      { path: "/screen6/score", element: <Screen6Score /> },
      { path: "/screen6/thankyou", element: <Screen6ThankYou /> },
    ],
  },
  {
    path: "/screen7",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Screen7 /> },
      { path: "/screen7/quiz", element: <Screen7Quiz /> },
      { path: "/screen7/instructions", element: <Screen7Instructions /> },
      { path: "/screen7/score", element: <Screen7Score /> },
      { path: "/screen7/video", element: <Screen7Video /> },
      { path: "/screen7/thankyou", element: <Screen7Thankyou /> },
    ],
  },
  {
    path: "/screen8",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Screen8 /> },
      { path: "/screen8/options", element: <Screen8Options /> },
      { path: "/screen8/viewer", element: <Screen8Viewer /> },
      { path: "/screen8/thankYou", element: <Screen8ThankYou /> },
    ],
  },
  {
    path: "/screen9",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Screen9 /> },
      { path: "/screen9/detail1", element: <CarbonFootprintDetails /> },
      { path: "/screen9/detail2", element: <CarbonFootprintDetails2 /> },
      { path: "/screen9/quiz", element: <Screen9Quiz /> },
      { path: "/screen9/age", element: <Screen9Age /> },
      { path: "/screen9/instructions", element: <Screen9Instructions /> },
      { path: "/screen9/thankyou", element: <Screen9Thankyou /> },
    ],
  },
  {
    path: "/screen10",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Screen10 /> },
      { path: "/screen10/quiz", element: <Screen10Quiz /> },
      { path: "/screen10/age", element: <Screen10Age /> },
      { path: "/screen10/instructions", element: <Screen10Instructions /> },
      { path: "/screen10/detail1", element: <EcpDetails /> },
      { path: "/screen10/detail2", element: <EcpDetails2 /> },
      { path: "/screen10/thankyou", element: <Screen10Thankyou /> },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
    errorElement: <PageNotFound />,
  },
]);
