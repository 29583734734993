import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ICategoryTypesScreen8 = "pdf" | "docx" | "video";

const pdf1Data = [
  {
    thumbnail: process.env.PUBLIC_URL + "/assets/images/screen8/pdf1/1.jpg",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/assets/images/screen8/pdf1/2.jpg",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/assets/images/screen8/pdf1/3.jpg",
  },
  {
    thumbnail: process.env.PUBLIC_URL + "/assets/images/screen8/pdf1/4.jpg",
  },
];

const docsDataList = Array.from({ length: 49 }, (_, index) => ({
  thumbnail: `${process.env.PUBLIC_URL}/assets/images/screen8/pdf2/docxConverted-images-${index}.jpg`,
}));

interface IRoot {
  selectedOpyionType: ICategoryTypesScreen8 | null;
  sliderData: {
    thumbnail: string;
  }[];
}

const initialState: IRoot = {
  selectedOpyionType: null,
  sliderData: [],
};

const slice = createSlice({
  name: "screen8",
  initialState,
  reducers: {
    selectedOpyionType(
      state,
      action: PayloadAction<null | ICategoryTypesScreen8>
    ) {
      state.selectedOpyionType = action.payload;
      if (action.payload === "pdf") {
        state.sliderData = pdf1Data;
      } else if (action.payload === "docx") {
        state.sliderData = docsDataList;
      }
    },
    resetAll(state) {
      state.selectedOpyionType = null;
      state.sliderData = [];
    },
  },
});

export const Screen8Actions = slice.actions;

export const reducer = slice.reducer;

export default slice;
