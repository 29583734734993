import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const screen7Questions = [
  {
    id: 1,
    question: "ماذا يحدث للبلاستيك عندما يُرمى في البيئة؟",
    options: {
      optionA: "يتحلل",
      optionB: "يذوب",
      optionC: "يبقى مثل ما هو لسنوات كثيرة",
    },
    correctOptionValue: "يبقى مثل ما هو لسنوات كثيرة",
  },
  {
    id: 2,
    question: "ماذا تشعر عندما ترى الحديقة مليئة بالنفايات؟ ",
    options: {
      optionA: "الفرح",
      optionB: "الحزن",
      optionC: "عدم الاهتمام",
    },
    correctOptionValue: "الحزن",
  },
  {
    id: 3,
    question: "كيف يمكن أن نكون أبطال البيئة؟",
    options: {
      optionA: "نهتم بمكونات البيئة",
      optionB: "نرمي النفايات ونستخدم البلاستيك",
      optionC: "نلعب الكرة في الشاطئ ",
    },
    correctOptionValue: "نهتم بمكونات البيئة",
  },
  {
    id: 4,
    question: "أفضل طريقة لحفظ الطعام؟",
    options: {
      optionA: "علب بلاستيكية",
      optionB: "زجاجية",
      optionC: "ورقية",
    },
    correctOptionValue: "زجاجية",
  },
  {
    id: 5,
    question: "أضرار رمي البلاستيك على الشاطئ؟",
    options: {
      optionA: "منظر غير صحي",
      optionB: "يؤثر على الطيور والأسماك",
      optionC: "لا يؤثر",
    },
    correctOptionValue: "يؤثر على الطيور والأسماك",
  },
  {
    id: 6,
    question: "أفضل الأكياس المستخدمة للتسوق وصديقة للبيئة؟",
    options: {
      optionA: "أكياس بلاستيكية خفيفة",
      optionB: "قماشية",
      optionC: "أكياس بلاستيكية ثقيلة",
    },
    correctOptionValue: "قماشية",
  },
];

export interface IScreen2Options {
  optionA: string | null;
  optionB: string | null;
  optionC: string | null;
  optionD?: string | null;
}

export interface IScreen7Questions {
  id: number;
  question: string;
  options: IScreen2Options;
  correctOptionValue: string;
}

interface IRoot {
  currentQuestion: IScreen7Questions | null;
  remainingQuestions: IScreen7Questions[];
  correctAnswers: number;
  currentIndex: number;
}

const initialState: IRoot = {
  currentQuestion: screen7Questions[0],
  remainingQuestions: screen7Questions.slice(1),
  correctAnswers: 0,
  currentIndex: 0,
};

const slice = createSlice({
  name: "screen2",
  initialState,
  reducers: {
    initialQuizDat(state) {
      state.currentQuestion = screen7Questions[0];
      state.remainingQuestions = screen7Questions.splice(1);
    },
    currentQuestion(state, action: PayloadAction<IScreen7Questions | null>) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(state, action: PayloadAction<IScreen7Questions[]>) {
      state.remainingQuestions = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    currentIndex(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload;
    },
    resetAll(state) {
      state.currentQuestion = initialState.currentQuestion;
      state.remainingQuestions = initialState.remainingQuestions;
      state.correctAnswers = 0;
    },
  },
});

export const plasticEffectsActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
