import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const maitainingEnvironmentQuestions = [
  {
    id: 1,
    question: "What is the capital of France?",
    options: {
      optionA: "Berlin",
      optionB: "Paris",
      optionC: "Rome",
      optionD: "Madrid",
    },
    correctOptionValue: "Paris",
  },
  {
    id: 2,
    question: "Which planet is known as the Red Planet?",
    options: {
      optionA: "Earth",
      optionB: "Mars",
      optionC: "Jupiter",
      optionD: "Saturn",
    },
    correctOptionValue: "Mars",
  },
  {
    id: 3,
    question: "Who wrote 'To Kill a Mockingbird'?",
    options: {
      optionA: "Harper Lee",
      optionB: "Mark Twain",
      optionC: "George Orwell",
      optionD: "J.K. Rowling",
    },
    correctOptionValue: "Harper Lee",
  },
  {
    id: 4,
    question: "What is the chemical symbol for water?",
    options: {
      optionA: "O2",
      optionB: "H2",
      optionC: "H2O",
      optionD: "CO2",
    },
    correctOptionValue: "H2O",
  },
  {
    id: 5,
    question: "In which year did the Titanic sink?",
    options: {
      optionA: "1905",
      optionB: "1912",
      optionC: "1918",
      optionD: "1925",
    },
    correctOptionValue: "1912",
  },
];

export interface IScreen2Options {
  optionA: string | null;
  optionB: string | null;
  optionC: string | null;
  optionD: string | null;
}

export interface IMaitainingEnvironmentQuestions {
  id: number;
  question: string;
  options: IScreen2Options;
  correctOptionValue: string;
}

interface IRoot {
  currentQuestion: IMaitainingEnvironmentQuestions | null;
  remainingQuestions: IMaitainingEnvironmentQuestions[];
  correctAnswers: number;
}

const initialState: IRoot = {
  currentQuestion: maitainingEnvironmentQuestions[0],
  remainingQuestions: maitainingEnvironmentQuestions.slice(1),
  correctAnswers: 0,
};

const slice = createSlice({
  name: "screen2",
  initialState,
  reducers: {
    initialQuizDat(state) {
      state.currentQuestion = maitainingEnvironmentQuestions[0];
      state.remainingQuestions = maitainingEnvironmentQuestions.splice(1);
    },
    currentQuestion(state, action: PayloadAction<IMaitainingEnvironmentQuestions | null>) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(state, action: PayloadAction<IMaitainingEnvironmentQuestions[]>) {
      state.remainingQuestions = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    resetAll(state) {
      state.currentQuestion = initialState.currentQuestion;
      state.remainingQuestions = initialState.remainingQuestions;
      state.correctAnswers = 0;
    },
  },
});

export const MaitainingEnvironmentActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
