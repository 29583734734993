import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ICategoryTypes = "time" | "shielding" | "distance";
interface IRoot {
  selectedCategory: ICategoryTypes | null;
  selectedOptionValue: number;
  selectedHour: number;
  distanceInMeters: number;
}

const initialState: IRoot = {
  selectedCategory: "time",
  selectedOptionValue: 1,
  selectedHour: 1,
  distanceInMeters: 1,
};

const slice = createSlice({
  name: "radiation",
  initialState,
  reducers: {
    selectedCategory(state, action: PayloadAction<null | ICategoryTypes>) {
      state.selectedCategory = action.payload;
    },
    selectedOptionValue(state, action: PayloadAction<number>) {
      state.selectedOptionValue = action.payload;
    },
    selectedHour(state, action: PayloadAction<number>) {
      state.selectedHour = action.payload;
    },
    distanceInMeters(state, action: PayloadAction<number>) {
      state.distanceInMeters = action.payload;
    },
    resetAll(state) {
      state.selectedCategory = "time";
      state.selectedOptionValue = 1;
      state.selectedHour = 1;
      state.distanceInMeters = 1;
    },
  },
});

export const RadiationActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
