import { combineReducers } from "@reduxjs/toolkit";
import { reducer as user } from "../slices/user";
import { reducer as persistStore } from "../slices/persistStore";
import { reducer as safetyHero } from "../slices/safetyHero";
import { reducer as screen2 } from "../slices/screen2";
import { reducer as gameQuiz } from "../slices/gameQuiz";
import { reducer as sewageTrash } from "../slices/sewageTrash";
import { reducer as plasticEffects } from "../slices/plasticEffect";
import { reducer as carbonFootprint } from "../slices/carbonFootprint";
import { reducer as maitainingEnvironment } from "../slices/maitainingEnvironment";
import { reducer as radiation } from "../slices/radiation";
import { reducer as sewageTreatment } from "../slices/sewageTreatment";
import { reducer as screen8 } from "../slices/screen8";
import {reducer as ECP} from "../slices/ecp";

const rootReducer = combineReducers({
  user,
  persistStore,
  safetyHero,
  screen2,
  gameQuiz,
  sewageTrash,
  plasticEffects,
  carbonFootprint,
  maitainingEnvironment,
  radiation,
  sewageTreatment,
  screen8,
  ECP,
});

export default rootReducer;
