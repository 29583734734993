import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const QuizAbove6Age = [
  {
    id: 1,
    question: "ما هي أفضل وسيلة نقل لتقليل البصمة الكربونية؟",
    options: {
      optionA: "السيارة",
      optionB: "الدراجة الهوائية",
      optionC: "الطائرة",
      optionD: "حافلة"
    },
    correctOptionValue: "الدراجة الهوائية",
  },
  {
    id: 2,
    question: "ما الذي تحتاجه النباتات لتنمو؟",
    options: {
      optionA: "ماء وضوء الشمس",
      optionB: "كهرباء",
      optionC: "طعام",
      optionD: "هواء فقط"
    },
    correctOptionValue: "ماء وضوء الشمس",
  },
  {
    id: 3,
    question: "أي من هذه الكائنات يعيش في البحر؟",
    options: {
      optionA: "سمكة",
      optionB: "قطة",
      optionC: "أسد",
      optionD: "طائر"
    },
    correctOptionValue: "سمكة",
  },
  {
    id: 4,
    question: "ما هو الشيء الذي يمكننا إعادة تدويره؟",
    options: {
      optionA: "الورق",
      optionB: "الطعام",
      optionC: "الحجارة",
      optionD: "الهواء"
    },
    correctOptionValue: "الورق",
  },
  {
    id: 5,
    question: "أي من هذه الكائنات يصنع العسل؟",
    options: {
      optionA: "النحلة",
      optionB: "الفراشة",
      optionC: "الطائر",
      optionD: "النملة"
    },
    correctOptionValue: "النحلة",
  },
  {
    id: 6,
    question: "ماذا يمكننا فعل لتقليل النفايات؟",
    options: {
      optionA: "إعادة التدوير",
      optionB: "رمي النفايات في الشارع",
      optionC: "حرق البلاستيك",
      optionD: "استخدام المزيد من البلاستيك"
    },
    correctOptionValue: "إعادة التدوير",
  },
  {
    id: 7,
    question: "ما هي الألوان التي نجدها عادةً في الغابة؟",
    options: {
      optionA: "الأخضر والبني",
      optionB: "الأحمر والبرتقالي",
      optionC: "الأزرق والأصفر",
      optionD: "الأسود والأبيض"
    },
    correctOptionValue: "الأخضر والبني",
  },
  {
    id: 8,
    question: "ما الذي يسببه تلوث الهواء؟ ",
    options: {
      optionA: "الأمراض",
      optionB: "تحسين الهواء",
      optionC: "زيادة الأكسجين",
      optionD: "طائر"
    },
    correctOptionValue: "الأمراض",
  },
];
export const QuizAbove13Age = [
  {
    id: 1,
    question: "ما هو السبب الرئيسي لتلوث الهواء في المدن؟ ",
    options: {
      optionA: "عوادم السيارات",
      optionB: "الأشجار",
      optionC: "الحيوانات",
      optionD: "البحار"
    },
    correctOptionValue: "عوادم السيارات",
  },
  {
    id: 2,
    question: "ما هو الأثر البيئي لإلقاء النفايات البلاستيكية في المحيط؟ ",
    options: {
      optionA: "تلوث البحار والمحيطات",
      optionB: "زيادة الأكسجين",
      optionC: "تحسين حياة الأسماك",
      optionD: "لا تأثير"
    },
    correctOptionValue: "تلوث البحار والمحيطات",
  },
  {
    id: 3,
    question: "كيف يمكننا تقليل تلوث الهواء؟ ",
    options: {
      optionA: "استخدام وسائل النقل العامة",
      optionB: "استخدام المزيد من السيارات",
      optionC: "حرق النفايات",
      optionD: "بناء مصانع أكثر"
    },
    correctOptionValue: "استخدام وسائل النقل العامة",
  },  {
    id: 4,
    question: "أي من هذه الأفعال يضر بطبقة الأوزون؟ ",
    options: {
      optionA: "استخدام غازات الكلوروفلوروكربون",
      optionB: "زراعة الأشجار",
      optionC: "استخدام الطاقة الشمسية",
      optionD: "تنظيف البحا"
    },
    correctOptionValue: "استخدام غازات الكلوروفلوروكربون",
  },  {
    id: 5,
    question: "ما هي النتيجة الرئيسية لإزالة الغابات؟ ",
    options: {
      optionA: " زيادة ثاني أكسيد الكربون في الجو ",
      optionB: "تقليل التصحر",
      optionC: "تحسين التربة",
      optionD: "زيادة الحيوانات"
    },
    correctOptionValue: " زيادة ثاني أكسيد الكربون في الجو ",
  },  {
    id: 6,
    question: "أي من هذه الموارد الطبيعية يعتبر موردًا متجددًا؟ ",
    options: {
      optionA: "الشمس",
      optionB: "الفحم",
      optionC: "النفط",
      optionD: "الغاز الطبيعي"
    },
    correctOptionValue: "الشمس",
  },  {
    id: 7,
    question: "ما هو أحد الحلول لإدارة النفايات؟ ",
    options: {
      optionA: "إعادة التدوير",
      optionB: "دفن النفايات",
      optionC: "حرقها",
      optionD: "رميها في البحر"
    },
    correctOptionValue: "إعادة التدوير",
  },  {
    id: 8,
    question: "ما هو الدور الذي تلعبه الأشجار في البيئة؟ ",
    options: {
      optionA: "إنتاج الأكسجين",
      optionB: "زيادة ثاني أكسيد الكربون",
      optionC: " تقليل الأكسجين",
      optionD: "إنتاج البلاستيك"
    },
    correctOptionValue: "إنتاج الأكسجين",
  },
];
export const QuizAbove16Age = [
  {
    id: 1,
    question: "ما هو السبب الرئيسي للاحتباس الحراري؟",
    options: {
      optionA: "زيادة انبعاثات ثاني أكسيد الكربون ",
      optionB: "تقليل استخدام الوقود الأحفوري",
      optionC: "زيادة زراعة الأشجار",
      optionD: "استخدام الطاقة المتجددة"
    },
    correctOptionValue: "زيادة انبعاثات ثاني أكسيد الكربون ",
  },
  {
    id: 2,
    question: "أي من هذه المصادر يعتبر طاقة غير متجددة؟",
    options: {
      optionA: "النفط",
      optionB: "الطاقة الشمسية",
      optionC: "الرياح",
      optionD: "الماء"
    },
    correctOptionValue: "النفط",
  },
 {
    id: 3,
    question: "ما هي الطريقة الأكثر فعالية لتقليل الانبعاثات الحرارية؟ ",
    options: {
      optionA: "استخدام الطاقة المتجددة",
      optionB: "حرق المزيد من الوقود الأحفوري",
      optionC: " بناء المزيد من المصانع",
      optionD: "قطع الأشجار "
    },
    
    correctOptionValue: "استخدام الطاقة المتجددة",
  },
  {
    id: 4,
    question: "ما هو التأثير السلبي لارتفاع مستوى سطح البحر؟ ",
    options: {
      optionA: "غرق المناطق الساحلية",
      optionB: "زيادة مساحة الأراضي الزراعية",
      optionC: "تحسين جودة الهواء",
      optionD: "زيادة الجليد في القطبين"
    },
    
    correctOptionValue: "غرق المناطق الساحلية",
  },
  {
    id: 5,
    question: "كيف يمكننا تقليل استهلاك الطاقة في المدن؟ ",
    options: {
      optionA: "استخدام وسائل النقل العام ",
      optionB: "بناء مزيد من الطرق",
      optionC: "زيادة استخدام السيارات الخاصة",
      optionD: "استخدام الأضواء ليلًا ونهارًا"
    },
    
    correctOptionValue: "استخدام وسائل النقل العام",
  },
  {
    id: 6,
    question: "ما هي الفائدة الرئيسية من استخدام الطاقة الشمسية؟ ",
    options: {
      optionA: "تقليل التلوث",
      optionB: "زيادة استهلاك الموارد الطبيعية",
      optionC: "تحسين جودة الهواء",
      optionD: "تقليل الاعتماد على الوقود الأحفوري"
    },
    
    correctOptionValue: "تقليل التلوث",
  },
  {
    id: 7,
    question: "ما هو الدور الرئيسي للمحيطات في تنظيم المناخ العالمي؟ ",
    options: {
      optionA: "امتصاص ثاني أكسيد الكربون",
      optionB: "زيادة درجات الحرارة",
      optionC: "تقليل الغازات الدفيئة",
      optionD: "تقليل مستوى سطح البحر"
    },
    
    correctOptionValue: "امتصاص ثاني أكسيد الكربون",
  },
  {
    id: 8,
    question: "ما هو تأثير إزالة الغابات على المناخ؟ ",
    options: {
      optionA: "زيادة الاحتباس الحراري",
      optionB: "تحسين المناخ",
      optionC: "تقليل الجفاف",
      optionD: "زيادة هطول الأمطار"
    },
    
    correctOptionValue: "زيادة الاحتباس الحراري",
  },
  
];
export const QuizAbove20Age = [
  {
    id: 1,
    question: "ما هو الهدف الرئيسي لاتفاقية باريس للمناخ؟",
    options: {
      optionA: "خفض الانبعاثات الحرارية العالمية",
      optionB: "تعزيز استخدام الفحم",
      optionC: "زيادة تلوث الهواء",
      optionD: "تقليل استخدام الطاقة الشمسية"
    },
    correctOptionValue: "خفض الانبعاثات الحرارية العالمية",
  },
  {
    id: 2,
    question: "ما هي أكبر مصادر التلوث البحري؟ ",
    options: {
      optionA: "البلاستيك",
      optionB: "النفط",
      optionC: "مياه الصرف الصحي",
      optionD: "كل ما سبق"
    },
    correctOptionValue: "كل ما سبق",
  },
  {
    id: 3,
    question: "ما هي أكثر أنواع الطاقة المستدامة استخدامًا حول العالم؟ ",
    options: {
      optionA: "الطاقة الشمسية",
      optionB: "الطاقة النووية",
      optionC: "الطاقة من الفحم",
      optionD: "الطاقة الهيدروجينية"
    },
    
    correctOptionValue: "الطاقة الشمسية",
  },
  {
    id: 4,
    question: "ما هو تعريف الاقتصاد الدائري؟ ",
    options: {
      optionA: "نظام اقتصادي يعتمد على إعادة استخدام الموارد وتقليل الفاقد",
      optionB: "نظام يعتمد على استهلاك المزيد من الوقود الأحفوري",
      optionC: "نظام يستخدم طاقة غير متجددة",
      optionD: "لا علاقة له بالبيئة"
    },
    
    correctOptionValue: "نظام اقتصادي يعتمد على إعادة استخدام الموارد وتقليل الفاقد",
  },
  {
    id: 5,
    question: "أي من هذه الإجراءات يقلل من انبعاثات الكربون؟",
    options: {
      optionA: "تقليل استهلاك الطاقة",
      optionB: "استخدام الأكياس البلاستيكية",
      optionC: "قطع الأشجار ",
      optionD: "استخدام الوقود الأحفوري"
    },
    
    correctOptionValue: "تقليل استهلاك الطاقة",
  },
  {
    id: 6,
    question: "ما هو الهدف من استخدام الطاقة المتجددة؟",
    options: {
      optionA: "حماية البيئة وتقليل التلوث",
      optionB: "زيادة الانبعاثات",
      optionC: "استهلاك المزيد من الوقود الأحفوري",
      optionD: "لا هدف"
    },
    
    correctOptionValue: "حماية البيئة وتقليل التلوث",
  },
  {
    id: 7,
    question: "كيف يمكن لوسائل النقل العامة أن تساهم في حماية البيئة؟",
    options: {
      optionA: "تقليل عدد السيارات على الطرق",
      optionB: "زيادة الازدحام",
      optionC: "زيادة التلوث",
      optionD: "لا تأثير"
    },
    
    correctOptionValue: "تقليل عدد السيارات على الطرق",
  },
  {
    id: 8,
    question: "ما هي أفضل الممارسات للحد من انبعاثات الكربون في قطاع الأعمال؟",
    options: {
      optionA: "استخدام الطاقة المتجددة وتقنيات كفاءة الطاقة",
      optionB: "زيادة ساعات العمل في المصانع",
      optionC: "الاعتماد على الوقود الأحفوري بشكل أكبر",
      optionD: "زيادة تشغيل الآلات في المصانع"
    },
    
    correctOptionValue: "استخدام الطاقة المتجددة وتقنيات كفاءة الطاقة",
  },
];
export interface IScreen2Options {
  optionA: string | null;
  optionB: string | null;
  optionC: string | null;
  optionD?: string | null;
}

export interface IECPQuestions {
  id: number;
  question: string;
  options: IScreen2Options;
  correctOptionValue: string;
}

interface IRoot {
  currentQuestion: IECPQuestions | null;
  remainingQuestions: IECPQuestions[];
  correctAnswers: number;
  selectedAge?: string;
  selectedQuizLength: number;
  quizData: IECPQuestions[];
}

const initialState: IRoot = {
  currentQuestion: QuizAbove6Age[0],
  remainingQuestions: QuizAbove6Age.slice(1),
  correctAnswers: 0,
  selectedAge: '',
  selectedQuizLength: 0,
  quizData: [],
};

const slice = createSlice({
  name: "screen2",
  initialState,
  reducers: {
    // initialQuizDat(state) {
    //   state.currentQuestion = QuizAbove6Age[0];
    //   state.remainingQuestions = QuizAbove6Age.splice(1);
    // },
    currentQuestion(state, action: PayloadAction<IECPQuestions | null>) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(state, action: PayloadAction<IECPQuestions[]>) {
      state.remainingQuestions = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    selectedAge(state, action: PayloadAction<any>) {
      state.selectedAge = action.payload;
    },
    setQuizData(state, action: PayloadAction<any>) {
      const type = action.payload;
      if (type === "6-12 سنة") {
        state.selectedQuizLength = QuizAbove6Age.length;
        state.quizData = QuizAbove6Age;
        state.currentQuestion = QuizAbove6Age[0];
        state.remainingQuestions = QuizAbove6Age.slice(1);
      } else if (type === "13-15 سنة") {
        state.selectedQuizLength = QuizAbove13Age.length;
        state.quizData = QuizAbove13Age;
        state.currentQuestion = QuizAbove13Age[0];
        state.remainingQuestions = QuizAbove13Age.slice(1);
      } else if (type === "16-19 سنة") {
        state.selectedQuizLength = QuizAbove16Age.length;
        state.quizData = QuizAbove16Age;
        state.currentQuestion = QuizAbove16Age[0];
        state.remainingQuestions = QuizAbove16Age.slice(1);
      } else if (type === "فوق 20 سنة") {
        state.selectedQuizLength = QuizAbove20Age.length;
        state.quizData = QuizAbove20Age;
        state.currentQuestion = QuizAbove20Age[0];
        state.remainingQuestions = QuizAbove20Age.slice(1);
      }
    },
    resetAll(state) {
      state.currentQuestion = initialState.currentQuestion;
      state.remainingQuestions = initialState.remainingQuestions;
      state.correctAnswers = 0;
    },
  },
});

export const ECPActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
