import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const QuizAbove6Age = [
  {
    id: 1,
    question: "ما هي البصمة الكربونية؟",
    options: {
      optionA: "نسبة المياه المستهلكة",
      optionB: "كمية الغازات الدفيئة الناتجة عن الأنشطة البشرية",
      optionC: "عدد الأشجار المزروعة",
    },
    correctOptionValue: "كمية الغازات الدفيئة الناتجة عن الأنشطة البشرية",
  },
  {
    id: 2,
    question: "أي من هذه الأنشطة يقلل البصمة الكربونية؟",
    options: {
      optionA: "استخدام الدراجة الهوائية",
      optionB: "استخدام السيارة كل يوم",
      optionC: "تشغيل الأضواء طوال اليوم",
    },
    correctOptionValue: "استخدام الدراجة الهوائية",
  },
  {
    id: 3,
    question: "ماذا يحدث إذا أطفأت الأضواء عندما لا تحتاج إليها؟ ",
    options: {
      optionA: "تزيد الكهرباء",
      optionB: "تقلل من استهلاك الطاقة",
      optionC: "تزداد البصمة الكربونية",
    },
    correctOptionValue: "تقلل من استهلاك الطاقة",
  },
];
export const QuizAbove13Age = [
  {
    id: 1,
    question: "كيف تساهم السيارات في زيادة البصمة الكربونية؟ ",
    options: {
      optionA: "من خلال انبعاثات ثاني أكسيد الكربون",
      optionB: "من خلال إنتاج الكهرباء",
      optionC: "من خلال تحسين جودة الهواء",
    },
    correctOptionValue: "من خلال انبعاثات ثاني أكسيد الكربون",
  },
  {
    id: 2,
    question: "ما هو التأثير البيئي للبلاستيك غير المعاد تدويره؟",
    options: {
      optionA: "يزيد البصمة الكربونية",
      optionB: "يقلل التلوث",
      optionC: "يحسن التربة",
    },
    correctOptionValue: "يزيد البصمة الكربونية",
  },
];
export const QuizAbove16Age = [
  {
    id: 1,
    question: "ما هي الغازات الأساسية التي تؤدي إلى زيادة البصمة الكربونية؟ ",
    options: {
      optionA: "الأكسجين",
      optionB: "ثاني أكسيد الكربون",
      optionC: "الهيليوم",
    },
    correctOptionValue: "ثاني أكسيد الكربون",
  },
  {
    id: 2,
    question:
      "كيف يمكن تقليل البصمة الكربونية الناتجة عن استخدام الطاقة في المنازل؟",
    options: {
      optionA: "استخدام الأجهزة ذات الكفاءة العالية",
      optionB: "استخدام المزيد من الأجهزة الكهربائية",
      optionC: "زيادة استهلاك الكهرباء في ساعات الذروة",
    },
    correctOptionValue: "استخدام الأجهزة ذات الكفاءة العالية",
  },
];
export const QuizAbove20Age = [
  {
    id: 1,
    question: "ما هي البصمة الكربونية للفرد؟ ",
    options: {
      optionA: "كمية الطاقة الكهربائية المستهلكة فقط",
      optionB: "مجموع انبعاثات غازات الدفيئة الناتجة عن نشاطات الفرد",
      optionC: "استهلاك المياه بشكل يومي",
    },
    correctOptionValue: "مجموع انبعاثات غازات الدفيئة الناتجة عن نشاطات الفرد",
  },
  {
    id: 2,
    question:
      "كيف يمكن لتطوير أنظمة النقل العام أن يقلل من البصمة الكربونية في المدن؟",
    options: {
      optionA: "من خلال تقليل الاعتماد على السيارات الخاصة",
      optionB: "من خلال زيادة استهلاك الوقود الأحفوري",
      optionC: "من خلال تحسين استهلاك الكهرباء في المنازل",
    },
    correctOptionValue: "من خلال تقليل الاعتماد على السيارات الخاصة",
  },
];
export interface IScreen2Options {
  optionA: string | null;
  optionB: string | null;
  optionC: string | null;
  optionD?: string | null;
}

export interface ICarbonFootprintQuestions {
  id: number;
  question: string;
  options: IScreen2Options;
  correctOptionValue: string;
}

interface IRoot {
  currentQuestion: ICarbonFootprintQuestions | null;
  remainingQuestions: ICarbonFootprintQuestions[];
  correctAnswers: number;
  selectedAge?: string;
  selectedQuizLength: number;
  quizData: ICarbonFootprintQuestions[];
}

const initialState: IRoot = {
  currentQuestion: QuizAbove6Age[0],
  remainingQuestions: QuizAbove6Age.slice(1),
  correctAnswers: 0,
  selectedAge: "",
  selectedQuizLength: 0,
  quizData: [],
};

const slice = createSlice({
  name: "screen2",
  initialState,
  reducers: {
    // initialQuizDat(state) {
    //   state.currentQuestion = QuizAbove6Age[0];
    //   state.remainingQuestions = QuizAbove6Age.splice(1);
    // },
    currentQuestion(
      state,
      action: PayloadAction<ICarbonFootprintQuestions | null>
    ) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(
      state,
      action: PayloadAction<ICarbonFootprintQuestions[]>
    ) {
      state.remainingQuestions = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    selectedAge(state, action: PayloadAction<any>) {
      state.selectedAge = action.payload;
    },
    setQuizData(state, action: PayloadAction<any>) {
      const type = action.payload;
      if (type === "6-12 سنة") {
        state.selectedQuizLength = QuizAbove6Age.length;
        state.quizData = QuizAbove6Age;
        state.currentQuestion = QuizAbove6Age[0];
        state.remainingQuestions = QuizAbove6Age.slice(1);
      } else if (type === "13-15 سنة") {
        state.selectedQuizLength = QuizAbove13Age.length;
        state.quizData = QuizAbove13Age;
        state.currentQuestion = QuizAbove13Age[0];
        state.remainingQuestions = QuizAbove13Age.slice(1);
      } else if (type === "16-19 سنة") {
        state.selectedQuizLength = QuizAbove16Age.length;
        state.quizData = QuizAbove16Age;
        state.currentQuestion = QuizAbove16Age[0];
        state.remainingQuestions = QuizAbove16Age.slice(1);
      } else if (type === "فوق 20 سنة") {
        state.selectedQuizLength = QuizAbove20Age.length;
        state.quizData = QuizAbove20Age;
        state.currentQuestion = QuizAbove20Age[0];
        state.remainingQuestions = QuizAbove20Age.slice(1);
      }
    },
    resetAll(state) {
      state.currentQuestion = initialState.currentQuestion;
      state.remainingQuestions = initialState.remainingQuestions;
      state.correctAnswers = 0;
    },
  },
});

export const carbonFootprintActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
