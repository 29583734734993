/* eslint-disable react/no-unescaped-entities */
import styled from "@emotion/styled";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

// const logo =
//   process.env.PUBLIC_URL + "/assets/images/carbon-footprint/logo.svg";
const backgroundCOver =
  process.env.PUBLIC_URL + "/assets/images/carbon-footprint/new/details1.svg";
const FootprintFrame =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Frame-carbon-footprint.svg";
const backBtn =
  process.env.PUBLIC_URL + "/assets/images/carbon-footprint/new/backIcon.svg";
const blueRightArrow =
  process.env.PUBLIC_URL + "/assets/images/carbon-footprint/new/nextIcon.svg";
const Wrapper = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  backgroundImage: `url(${backgroundCOver})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

const ScreenWrapper = styled(Box)(() => ({
  width: "100vw",
  height: "auto",
  display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  flexDirection: "column",
  rowGap: "2vw",
  flexWrap: "wrap",
  paddingTop: "4vh",
}));

// const LogoWrapper = styled(Box)(() => ({
//   width: "7vw",
//   display: "flex",
//   margin: "auto",
// }));

const TextWrapper = styled(Typography)(() => ({
  fontFamily: "Luckiest Guy",
  letter: "4%",
  paddingTop: "1vh",
  background: "linear-gradient(90deg, #D1F7F2, #2DA9A6)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  fontWeight: 400,
  fontSize: "10vw",
}));

const Instructions = styled(Typography)(() => ({
  width: "90%",
  // height: "fit-content",
  direction: "rtl",
  marginTop: "2vh",
  color: "#FFFFFF",
  fontSize: "3vw",
}));

const BackButton = styled(IconButton)(() => ({
  width: "7vw",
  justifyContent: "flex-start",
  marginLeft: 0,
}));

const CarbonFootprintDetails = () => {
  const navigate = useNavigate();

  // const moveToNextPage = () => {
  //   navigate("/screen9/quiz");
  // };

  return (
    <Wrapper>
      <ScreenWrapper>
        <Box
          sx={{
            display: "flex",
            marginBotton: "2vh",
            padding: "0 2vw 0 2vw",
            width: "100%",
            position: "absolute",
            top: "8vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BackButton>
            <img
              src={backBtn}
              alt="button"
              width={"100%"}
              onClick={() => navigate("/screen9")}
            />
          </BackButton>
          {/* <LogoWrapper>
            <img
              src={logo}
              alt="logo"
              width={"100%"}
              style={{ visibility: "hidden" }}
            />
          </LogoWrapper> */}
          <BackButton onClick={() => navigate("/screen9/detail2")}>
            <img src={blueRightArrow} alt="button" width={"100%"} />
          </BackButton>
        </Box>
        <TextWrapper sx={{ visibility: "hidden" }}>
          تعريف البصمة الكربونية
        </TextWrapper>
        <Instructions sx={{ visibility: "hidden" }}>
          البصمة الكربونية هي مصطلح يُستخدم لوصف إجمالي انبعاثات غازات الاحتباس
          الحراري الناتجة عن أنشطة فرد أو مجموعة، وتقاس عادةً بوحدات الكربون
          المكافئ (CO₂e). تشمل هذه الانبعاثات الناتجة عن استخدام الطاقة، النقل،
          إنتاج المواد الغذائية، واستهلاك المنتجات. تعتبر البصمة الكربونية أداة
          مهمة لفهم تأثير سلوكياتنا على البيئة، مما يساعد على توجيه الجهود نحو
          تقليل هذه الانبعاثات وتحقيق التنمية المستدامة.
        </Instructions>
        <Box
          style={{
            width: "60%",
            paddingTop: "8vh",
            margin: "auto",
            visibility: "hidden",
          }}
        >
          <img src={FootprintFrame} width={"100%"} />
        </Box>
      </ScreenWrapper>
    </Wrapper>
  );
};

export default CarbonFootprintDetails;
