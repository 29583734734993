/* eslint-disable react/no-unescaped-entities */
import styled from "@emotion/styled";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

// const logo =
//   process.env.PUBLIC_URL + "/assets/images/carbon-footprint/logo.svg";
const backgroundCOver =
  process.env.PUBLIC_URL + "/assets/images/carbon-footprint/new/details2.svg";
const backBtn =
  process.env.PUBLIC_URL + "/assets/images/carbon-footprint/new/backIcon.svg";
const blueRightArrow =
  process.env.PUBLIC_URL + "/assets/images/carbon-footprint/new/nextIcon.svg";
const image1 =
  process.env.PUBLIC_URL + "/assets/images/carbon-footprint/image1.svg";
const image2 =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Group 772545622.svg";
const image3 =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Group 772545623.svg";
const image4 =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Group 772545624.svg";
const image5 =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Group 772545625.svg";
const image6 =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Group 772545627.svg";
const image7 =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Group 772545626.svg";
const image8 =
  process.env.PUBLIC_URL +
  "/assets/images/carbon-footprint/Group 772545628.svg";

const Wrapper = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  backgroundImage: `url(${backgroundCOver})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

const ScreenWrapper = styled(Box)(() => ({
  width: "100vw",
  height: "auto",
  display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  flexDirection: "column",
  rowGap: "2vw",
  flexWrap: "wrap",
  paddingTop: "4vh",
}));

// const LogoWrapper = styled(Box)(() => ({
//   width: "7vw",
//   display: "flex",
//   margin: "auto",
// }));

const TextWrapper = styled(Typography)(() => ({
  fontFamily: "Luckiest Guy",
  letter: "4%",
  paddingTop: "2vh",
  paddingBottom: "2vh",
  background: "linear-gradient(90deg, #08292B, #185657)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  fontWeight: 400,
  fontSize: "7vw",
}));

const BackButton = styled(IconButton)(() => ({
  width: "7vw",
  justifyContent: "flex-start",
  marginLeft: 0,
}));
const HeaderText = styled(Typography)(() => ({
  fontFamily: "Tajawal",
  letter: "4%",
  paddingTop: "1vh",
  background: "linear-gradient(90deg, #08292B, #185657)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "right",
  fontWeight: 700,
  fontSize: "2.5vw",
  direction: "rtl",
  margin: 0,
  padding: 0,
}));

const Content = styled(Typography)(() => ({
  fontFamily: "Tajawal",
  letter: "4%",
  paddingTop: "1vh",
  background: "linear-gradient(90deg, #08292B, #185657)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "right",
  fontWeight: 400,
  fontSize: "2.5vw",
  direction: "rtl",
  margin: 0,
  padding: 0,
}));

const ContainerWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "0 4vw 1vw 4vw",
  visibility: "hidden",
}));

const CarbonFootprintDetails2 = () => {
  const navigate = useNavigate();

  // const moveToNextPage = () => {
  //   navigate("/screen9/quiz");
  // };

  return (
    <Wrapper>
      <ScreenWrapper>
        <Box
          sx={{
            display: "flex",
            marginBotton: "2vh",
            padding: "0 2vw 0 2vw",
            width: "100%",
            position: "absolute",
            top: "8vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BackButton>
            <img
              src={backBtn}
              alt="button"
              width={"100%"}
              onClick={() => navigate("/screen9/detail1")}
            />
          </BackButton>
          {/* <LogoWrapper>
            <img src={logo} alt="logo" width={"100%"} />
          </LogoWrapper> */}
          <BackButton onClick={() => navigate("/screen9/age")}>
            <img src={blueRightArrow} alt="button" width={"100%"} />
          </BackButton>
        </Box>
        <TextWrapper sx={{ visibility: "hidden" }}>
          كيف نساهم في تقليل البصمة الكربونية؟
        </TextWrapper>
        <ContainerWrapper>
          <img src={image1} width={"12%"} />
          <Box>
            <HeaderText>تقليل استهلاك الطاقة</HeaderText>
            <Content>
              تقليل استهلاك الطاقة استخدام الأجهزة الكهربائية الموفرة للطاقة،
              وإطفاء الأضواء والأجهزة عند عدم استخدامها.
            </Content>
          </Box>
        </ContainerWrapper>
        <ContainerWrapper>
          <img src={image2} width={"12%"} />
          <Box>
            <HeaderText> تقليل استهلاك الطاقة</HeaderText>
            <Content>
              استخدام الأجهزة الكهربائية الموفرة للطاقة، وإطفاء الأنوار والأجهزة
              عند عدم استخدامها.
            </Content>
          </Box>
        </ContainerWrapper>
        <ContainerWrapper>
          <img src={image3} width={"12%"} />
          <Box>
            <HeaderText>استخدام وسائل النقل المستدامة</HeaderText>
            <Content>
              تناول كميات أقل من المنتجات الحيوانية للمساهمة في تقليل الانبعاثات
              الناتجة عن تربية الحيوانات.
            </Content>
          </Box>
        </ContainerWrapper>
        <ContainerWrapper>
          <img src={image4} width={"12%"} />
          <Box>
            <HeaderText>إعادة التدوير</HeaderText>
            <Content>
              فرز النفايات وإعادة تدوير المواد القابلة للتدوير مثل الورق،
              والزجاج، والبلاستيك.
            </Content>
          </Box>
        </ContainerWrapper>
        <ContainerWrapper>
          <img src={image5} width={"12%"} />
          <Box>
            <HeaderText> شراء المنتجات المحلية</HeaderText>
            <Content>
              اختيار المنتجات المحلية والموسمية لتقليل انبعاثات النقل.
            </Content>
          </Box>
        </ContainerWrapper>
        <ContainerWrapper>
          <img src={image6} width={"12%"} />
          <Box>
            <HeaderText>الاستثمار في الطاقة المتجددة </HeaderText>
            <Content>
              استخدام مصادر الطاقة المتجددة مثل الطاقة الشمسية أو الرياح.
            </Content>
          </Box>
        </ContainerWrapper>
        <ContainerWrapper>
          <img src={image8} width={"12%"} />
          <Box>
            <HeaderText> زراعة الأشجار </HeaderText>
            <Content>
              المشاركة في مشاريع التشجير، حيث تسهم الأشجار في امتصاص الكربون من
              الجو.
            </Content>
          </Box>
        </ContainerWrapper>
        <ContainerWrapper>
          <img src={image7} width={"12%"} />
          <Box>
            <HeaderText>تقليل استهلاك الطاقة</HeaderText>
            <Content>
              تقليل استهلاك الطاقة استخدام الأجهزة الكهربائية الموفرة للطاقة،
              وإطفاء الأضواء والأجهزة عند عدم استخدامها.
            </Content>
          </Box>
        </ContainerWrapper>
      </ScreenWrapper>
    </Wrapper>
  );
};

export default CarbonFootprintDetails2;
