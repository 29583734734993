import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const water =
  process.env.PUBLIC_URL + "/assets/images/screen1/options/water.svg";

const electricity =
  process.env.PUBLIC_URL + "/assets/images/screen1/options/electricity.svg";

const safetyHeroOptions = [
  {
    id: 0,
    title: "Electricity",
    thumbnail: electricity,
    value: "electricity" as ISelectedQuizTyp,
  },
  {
    id: 1,
    title: "Water",
    thumbnail: water,
    value: "water" as ISelectedQuizTyp,
  },
];

export const screen2Questions = [
  {
    id: 1,
    question: "تشغيل التلفاز طوال اليوم  …..",
    options: {
      optionA: "نعم لا يضر",
      optionB: "⁠لا يجب أن نغلقه في حالة عدم الاستخدام",
      optionC: "⁠يمكن تركه لفترة ثم إغلاقه",
    },
    correctOptionValue: "⁠لا يجب أن نغلقه في حالة عدم الاستخدام",
  },
  {
    id: 2,
    question:
      "ما هو تأثير ترك باب الثلاجة مفتوحًا لفترات طويلة على استهلاك الكهرباء؟",
    options: {
      optionA: "لا يؤثر على استهلاك الكهرباء.",
      optionB: "يزيد من استهلاك الكهرباء ويقلل من كفاءة التبريد.",
      optionC: "يؤثر فقط عند تشغيل الثلاجة",
    },
    correctOptionValue: "يزيد من استهلاك الكهرباء ويقلل من كفاءة التبريد.",
  },
  {
    id: 3,
    question:
      "استخدام المصابيح العادية في المنزل، بدل من المصابيح الموفرة للطاقة يعتبر ",
    options: {
      optionA: "أكثر إشراقًا .",
      optionB: "يجب استخدام المصابيح الموفرة للطاقة",
      optionC: "غير مهم في الفاتورة",
    },
    correctOptionValue: "يجب استخدام المصابيح الموفرة للطاقة",
  },
  {
    id: 4,
    question:
      "ماذا يجب أن تفعل إذا رأيت سلكا كهربائيا مكشوفا؟",
    options: {
      optionA: "لمسها لمعرفة ما إذا كانت تعمل",
      optionB: "أخبر شخصا بالغ أو متخصص",
      optionC: "أتجاهله لأنه ليس خطير",
    },
    correctOptionValue: "أخبر شخصا بالغ أو متخصص",
  },
];

export const waterQuizQuestions = [
  {
    id: 1,
    question:
      "ماذا يحدث إذا تركنا الصنبور مفتوحًا أثناء غسل اليدين؟",
    options: {
      optionA: "يساعد في توفير المياه.",
      optionB: "يؤدي إلى إهدار الكثير من المياه",
      optionC: "يجعل غسل اليدين أسرع.",
    },
    correctOptionValue: "يؤدي إلى إهدار الكثير من المياه",
  },
  {
    id: 2,
    question:
      "ما هي أفضل طريقة لغسل السيارة لتوفير الماء؟",
    options: {
      optionA: ".استخدام دلو من الماء وغسل السيارة به",
      optionB: " ترك الخرطوم مفتوحًا طوال الوقت",
      optionC: " غسل السيارة بالماء والصابون بدون شطف.",
    },
    correctOptionValue: ".استخدام دلو من الماء وغسل السيارة به",
  },
  {
    id: 3,
    question:
      "يوجد تسرب في صنبور المياه ماذا يجب أن تفعل إذا رأيت تسرب في صنبور المياه؟",
    options: {
      optionA: "تجاهله .. لا يهم",
      optionB: "⁠أغلق الصنبور وأخبر شخصا بالغا",
      optionC: "⁠أحاول إصلاحه بنفسي",
    },
    correctOptionValue: "⁠أغلق الصنبور وأخبر شخصا بالغا",
  },
  {
    id: 4,
    question:
      "ما هو أفضل وقت لسقي النباتات لتوفير الماء؟",
    options: {
      optionA: "عندما تكون الشمس قوية لتجف التربة بسرعة.",
      optionB: "في الصباح الباكر أو عند غروب الشمس لتبقى التربة رطبة لفترة أطول.",
      optionC: "في أي وقت من اليوم لأن ذلك لا يؤثر على كمية الماء",
    },
    correctOptionValue: "في الصباح الباكر أو عند غروب الشمس لتبقى التربة رطبة لفترة أطول.",
  },
];

export interface IScreen2Options {
  optionA: string | null;
  optionB: string | null;
  optionC: string | null;
  // optionD: string | null;
}

export interface IScreen2Questions {
  id: number;
  question: string;
  options: IScreen2Options;
  correctOptionValue: string;
}

export type ISelectedQuizTyp = "water" | "electricity";

export interface ISafetyHeroOptions {
  id: number;
  title: string;
  thumbnail: string;
  value: ISelectedQuizTyp;
}

interface IRoot {
  currentQuestion: IScreen2Questions | null;
  remainingQuestions: IScreen2Questions[];
  correctAnswers: number;
  safetyHeroOptions: ISafetyHeroOptions[];
  selectedQuizType: ISelectedQuizTyp | null;
  selectedQuizLength: number;
  currentIndex: number;
}

const initialState: IRoot = {
  currentQuestion: screen2Questions[0],
  remainingQuestions: screen2Questions.slice(1),
  correctAnswers: 0,
  safetyHeroOptions: safetyHeroOptions,
  selectedQuizType: null,
  selectedQuizLength: 0,
  currentIndex: 0,
};

const slice = createSlice({
  name: "screen2",
  initialState,
  reducers: {
    setQuizData(state, action: PayloadAction<ISelectedQuizTyp>) {
      const type = action.payload;
      if (type === "water") {
        state.selectedQuizLength = waterQuizQuestions.length;
        state.currentQuestion = waterQuizQuestions[0];
        state.remainingQuestions = waterQuizQuestions.slice(1);
        state.currentIndex = 0;
      } else if (type === "electricity") {
        state.selectedQuizLength = screen2Questions.length;
        state.currentQuestion = screen2Questions[0];
        state.remainingQuestions = screen2Questions.slice(1);
        state.currentIndex = 0;
      }
    },
    initialQuizDat(state) {
      state.currentQuestion = screen2Questions[0];
      state.remainingQuestions = screen2Questions.splice(1);
      state.currentIndex = 0;
    },
    currentQuestion(state, action: PayloadAction<IScreen2Questions | null>) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(state, action: PayloadAction<IScreen2Questions[]>) {
      state.remainingQuestions = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    currentIndex(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload;
    },
    selectedQuizType(state, action: PayloadAction<ISelectedQuizTyp>) {
      state.selectedQuizType = action.payload;
    },
    resetAll(state) {
      state.currentQuestion = initialState.currentQuestion;
      state.remainingQuestions = initialState.remainingQuestions;
      state.correctAnswers = 0;
      state.selectedQuizType = null;
      state.selectedQuizLength = 0;
      state.currentIndex = 0;
    },
  },
});

export const Screen2Actions = slice.actions;

export const reducer = slice.reducer;

export default slice;
