import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const safetyHeroOptions = [
  {
    id: 0,
    title: "Energy Saving Race",
    image: "",
    thumbnail: "",
    value: "energySavingRace" as ISelectedQuizTyp,
  },
  {
    id: 1,
    title: "Water Heroes",
    image: "",
    thumbnail: "",
    value: "waterHeroes" as ISelectedQuizTyp,
  },
  {
    id: 2,
    title: "Trash and Recycling",
    image: "",
    thumbnail: "",
    value: "trashAndRecycling" as ISelectedQuizTyp,
  },
];

const energySavingRaceQuestions = [
  {
    id: 0,
    question: "Is water composed of two hydrogen atoms and one oxygen atom?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail: "https://i.postimg.cc/Dyf9G3jQ/images.jpg",
  },
  {
    id: 1,
    question:
      "Can water exist in all three states of matter: solid, liquid, and gas?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail: "https://i.postimg.cc/Dyf9G3jQ/images.jpg",
  },
  {
    id: 2,
    question:
      "Does saltwater make up more than 90% of the Earth's water supply?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail: "https://i.postimg.cc/Dyf9G3jQ/images.jpg",
  },
  {
    id: 3,
    question: "Is pure water a good conductor of electricity?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
    thumbnail: "https://i.postimg.cc/Dyf9G3jQ/images.jpg",
  },
  {
    id: 4,
    question:
      "Is water's boiling point 100°C at standard atmospheric pressure?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail: "https://i.postimg.cc/Dyf9G3jQ/images.jpg",
  },
];

const waterHeroesQuestions = [
  {
    id: 0,
    question: "Does electricity flow through a conductor like copper?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail:
      "https://i.postimg.cc/ZnnW9D1T/510-X340-Water-Heroes-Winner-6month-333x217.png",
  },
  {
    id: 1,
    question:
      "Is alternating current (AC) the type of electricity used in homes?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail:
      "https://i.postimg.cc/ZnnW9D1T/510-X340-Water-Heroes-Winner-6month-333x217.png",
  },
  {
    id: 2,
    question: "Can electrical energy be converted into heat and light?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail:
      "https://i.postimg.cc/ZnnW9D1T/510-X340-Water-Heroes-Winner-6month-333x217.png",
  },
  {
    id: 3,
    question: "Is a battery a source of alternating current (AC)?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
    thumbnail:
      "https://i.postimg.cc/ZnnW9D1T/510-X340-Water-Heroes-Winner-6month-333x217.png",
  },
  {
    id: 4,
    question: "Does resistance in a circuit increase the flow of electricity?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
    thumbnail:
      "https://i.postimg.cc/ZnnW9D1T/510-X340-Water-Heroes-Winner-6month-333x217.png",
  },
];

const trashAndRecyclingQuestions = [
  {
    id: 0,
    question:
      "Is sewage water treated before being released back into the environment?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail: "https://i.postimg.cc/fbdpszys/trashrecyclingbins-1-2.jpg",
  },
  {
    id: 1,
    question: "Can untreated sewage contaminate drinking water sources?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail: "https://i.postimg.cc/fbdpszys/trashrecyclingbins-1-2.jpg",
  },
  {
    id: 2,
    question: "Is stormwater always treated in sewage treatment plants?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
    thumbnail: "https://i.postimg.cc/fbdpszys/trashrecyclingbins-1-2.jpg",
  },
  {
    id: 3,
    question:
      "Are septic tanks commonly used in areas without a centralized sewage system?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "Yes",
    thumbnail: "https://i.postimg.cc/fbdpszys/trashrecyclingbins-1-2.jpg",
  },
  {
    id: 4,
    question: "Can solid waste be safely disposed of into a sewer system?",
    optionA: "Yes",
    optionB: "No",
    correctAnswer: "No",
    thumbnail: "https://i.postimg.cc/fbdpszys/trashrecyclingbins-1-2.jpg",
  },
];

export type ISelectedQuizTyp =
  | "energySavingRace"
  | "waterHeroes"
  | "trashAndRecycling";

export interface IGameQuizOptions {
  id: number;
  title: string;
  image: string;
  thumbnail: string;
  value: ISelectedQuizTyp;
}

export interface ISafetyQuiz {
  id: number;
  question: string;
  optionA: string;
  optionB: string;
  correctAnswer: string;
  thumbnail: string;
}

interface IRoot {
  safetyHeroOptions: IGameQuizOptions[];
  quizData: ISafetyQuiz[];
  selectedQuizType: ISelectedQuizTyp | null;
  currentQuestion: ISafetyQuiz | null;
  remainingQuestions: ISafetyQuiz[];
  correctAnswers: number;
}

const initialState: IRoot = {
  safetyHeroOptions: safetyHeroOptions,
  quizData: [],
  selectedQuizType: null,
  currentQuestion: null,
  remainingQuestions: [],
  correctAnswers: 0,
};

const slice = createSlice({
  name: "gameQuiz",
  initialState,
  reducers: {
    setQuizData(state, action: PayloadAction<ISelectedQuizTyp>) {
      const type = action.payload;
      if (type === "energySavingRace") {
        state.quizData = energySavingRaceQuestions;
        state.currentQuestion = energySavingRaceQuestions[0];
        state.remainingQuestions = energySavingRaceQuestions.slice(1);
      } else if (type === "waterHeroes") {
        state.quizData = waterHeroesQuestions;
        state.currentQuestion = waterHeroesQuestions[0];
        state.remainingQuestions = waterHeroesQuestions.slice(1);
      } else if (type === "trashAndRecycling") {
        state.quizData = trashAndRecyclingQuestions;
        state.currentQuestion = trashAndRecyclingQuestions[0];
        state.remainingQuestions = trashAndRecyclingQuestions.slice(1);
      }
    },
    selectedQuizType(state, action: PayloadAction<ISelectedQuizTyp>) {
      state.selectedQuizType = action.payload;
    },
    correctAnswers(state) {
      state.correctAnswers = state.correctAnswers + 1;
    },
    currentQuestion(state, action: PayloadAction<ISafetyQuiz | null>) {
      state.currentQuestion = action.payload;
    },
    remainingQuestions(state, action: PayloadAction<ISafetyQuiz[]>) {
      state.remainingQuestions = action.payload;
    },
    resetAll(state) {
      state.selectedQuizType = null;
      state.currentQuestion = null;
      state.remainingQuestions = [];
      state.correctAnswers = 0;
      state.quizData = [];
    },
  },
});

export const GameQuizActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
